<template>
	<v-sheet class="quotaotion" style="height: calc(100vh - 88px)">
		<template v-if="!contentLoading">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<v-layout class="justify-content-between align-items-center">
						<v-flex md7 class="d-flex flex-wrap align-items-center header-heading">
							<p class="my-auto font-level-3-bold text-truncate" :style="`font-size: 20px`">
								<ShowValue :object="quotation" object-key="title" label="title"></ShowValue>
							</p>
							<div id="header-status">
								<ShowBarcode
									:object="quotation"
									object-key="barcode"
									tooltip
									tooltip-text="quotation #"
									click-to-copy
									label="barcode"
								></ShowBarcode>
								<v-chip
									v-if="quotation.status_value"
									label
									:color="`${getStatusColor(
										quotation.status_value
									)} white--text text--darken-4 text-capitilize`"
									class="mr-2"
								>
									<span class="text-capitalize">
										{{ quotation.status_value }}
									</span>
								</v-chip>
							</div>
						</v-flex>
						<v-flex md5 class="text-right">
							<template>
								<div class="ml-2 d-inline-flex align-items-center">
									<span class="fw-500" style="font-size: 20px">Total :</span>
									<span class="blue--text text--darken-4 ml-1 fw-500" style="font-size: 20px">
										<ShowPrice :object="quotation" object-key="total" label="total"></ShowPrice>
									</span>
								</div>
								<v-btn
									v-if="quotation.is_price_changed"
									color="blue darken-4 text-white"
									class="ml-2"
									depressed
									:disabled="updatePriceLoading"
									:loading="updatePriceLoading"
									tile
									@click="updateChangePrice()"
								>
									Update Price
								</v-btn>
								<v-btn
									v-if="lower_case(quotation.status_value) == 'approved'"
									color="red text-white"
									class="ml-2"
									depressed
									tile
									@click="downloadPDF()"
								>
									<v-icon small left>mdi-file-pdf</v-icon>PDF
								</v-btn>
								<template v-if="checkPermission()">
									<v-btn
										color="blue darken-4 text-white"
										v-if="getPermission('quotation::edit')"
										class="ml-2"
										depressed
										tile
										v-on:click="doAction('edit')"
									>
										<v-icon small left>mdi-pencil</v-icon>Edit
									</v-btn>
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="ml-2"
												depressed
												tile
												><v-icon small left>mdi-more mdi-flip-h</v-icon>More</v-btn
											>
										</template>
										<v-list class="py-0">
											<v-list-item v-on:click="doAction('send-for-approval')">
												<v-list-item-title>
													<v-icon left color="blue darken-4">mdi-email-send-outline</v-icon>
													Send For Approval</v-list-item-title
												>
											</v-list-item>
											<v-divider class="m-0 p-0"></v-divider>
											<v-list-item v-on:click="doAction('approve')">
												<v-list-item-title>
													<v-icon left color="green">mdi-check-all</v-icon>
													Approve</v-list-item-title
												>
											</v-list-item>
											<v-divider class="m-0 p-0"></v-divider>
											<v-list-item v-on:click="doAction('reject')">
												<v-list-item-title>
													<v-icon left color="red lighten-1">mdi-cancel</v-icon>
													<span class="red--text text--lighten-1"> Reject </span>
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
								<v-btn
									v-on:click="backToListing('quotation')"
									color="grey lighten-3"
									class="ml-2"
									depressed
									tile
									><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
								>
							</template>
						</v-flex>
					</v-layout>
					<div class="d-flex">
						<div class="w-10 pr-3" v-if="false">
							<ImageTemplate circle :src="quotation.image"></ImageTemplate>
						</div>
						<div class="width-50">
							<div class="d-flex flex-wrap">
								<div class="width-50">
									<div class="mt-2">
										<span class="fw-600">
											Ref Number
											<TooltipQuestion>
												<template v-slot:text>Reference Number Printed on pdf</template>
											</TooltipQuestion>
											:
										</span>
										<ShowValue :object="quotation" object-key="reference" label="ref number"></ShowValue>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2">
										<span class="fw-600 mr-2">Prepared By : </span>
										<span class="no-wrap fw-500">
											<ShowValue :object="quotation" object-key="prepared_by" label="Prepared By"></ShowValue>
										</span>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2">
										<span class="fw-600 mr-2">Sales Person : </span>
										<span class="no-wrap fw-500">
											<ShowValue
												:object="quotation.sales_person"
												object-key="display_name"
												label="sales person"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="width-50">
									<div class="mt-2 d-flex align-items-center">
										<span class="fw-600"> Rating : </span>
										<span class="no-wrap">
											<v-rating
												v-model="quotation.rating"
												:value="quotation.rating"
												background-color="orange lighten-3"
												color="orange"
												class="py-0"
												small
												readonly
												size="16"
											></v-rating>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="width-50 d-flex flex-wrap">
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600 mr-2">Created Date: </span>
									<span class="fw-500 no-wrap">
										{{ formatDateTime(quotation.added_at) }}
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Created by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="quotation" object-key="created_by" label="Created by"></ShowValue>
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Updated Date : </span>
									<span class="fw-500 no-wrap">
										<template v-if="quotation.updated_at">
											{{ formatDateTime(quotation.updated_at) }}
										</template>
										<em v-else class="text-muted">no updated</em>
									</span>
								</div>
							</div>
							<div class="width-50">
								<div class="mt-2">
									<span class="fw-600">Updated by : </span>
									<span class="fw-500 no-wrap">
										<ShowValue :object="quotation" object-key="updated_by" label="updated by"> </ShowValue>
									</span>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="quotationTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail"> <v-icon small left>mdi-information-outline</v-icon> Details</v-tab>
					<v-tab href="#line-items">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Line Items({{
							quotation.line_item_count
						}})</v-tab
					>
					<v-tab href="#pending" v-if="false">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Pending</v-tab
					>
					<v-tab href="#recieved" v-if="false">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Recieved</v-tab
					>
					<v-tab href="#attachment">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Attachments</v-tab
					>
					<v-tab href="#event"> <v-icon small left>mdi-history</v-icon> Logs</v-tab>
					<v-tab href="#quantity-by-location" v-if="false">
						<v-icon small left>mdi-map-marker-outline</v-icon> Quantity by Location</v-tab
					>
					<v-tab v-if="false && getPermission('transaction:view')" href="#transaction">
						<v-icon small left>mdi-share-outline</v-icon> Transaction
					</v-tab>
					<v-tab v-if="false" href="#reservation">
						<v-icon small left>mdi-calendar-check</v-icon> Reservations</v-tab
					>
				</v-tabs>
				<v-tabs-items v-model="quotationTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<v-expansion-panels
								flat
								accordion
								tile
								v-model="expansion_panel"
								multiple
								class="mt-5 transparent-panels overflow-y"
								style="max-height: calc(100vh - 250px)"
							>
								<v-expansion-panel>
									<v-expansion-panel-header>
										<v-layout>
											<v-flex md10>
												<strong>Customer</strong>
												-
												<span class="text-truncate mb-1 bold-500 text-capitalize">
													{{ quotation.customer && quotation.customer.display_name }}
												</span>
											</v-flex>
										</v-layout>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="contact-person-list">
										<template>
											<v-layout>
												<v-flex md6 lg3 v-if="quotation.customer">
													<div>
														<p
															class="text-truncate mb-1 bold-600 text-capitalize"
															v-if="quotation.customer && quotation.customer.display_name"
														>
															<span v-if="false">{{ quotation.customer.display_name }}</span>
															<span>Company details</span>
														</p>
													</div>
													<!-- {{ quotation.customer }} -->
													<p class="mb-1" v-if="quotation.customer.company_name">
														<v-icon left small>mdi-home-city-outline</v-icon>
														<ShowValue
															tooltip
															tooltip-text="company name"
															:object="quotation.customer"
															object-key="company_name"
															label="company name"
														></ShowValue>
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer.phone">
														<v-icon left small>mdi-phone</v-icon>{{ quotation.customer.phone }}
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer.email">
														<v-icon left small>mdi-email</v-icon>{{ quotation.customer.email }}
													</p>
													<p class="mb-1" v-if="quotation.customer.designation">
														<v-icon left small>mdi-briefcase</v-icon> {{ quotation.customer.designation }}
													</p>
													<div class="d-flex" v-if="false">
														<div>
															<v-icon left small>mdi-map</v-icon>
														</div>
														<div>
															<p class="mb-1" v-if="quotation.customer.unit_number">
																{{ quotation.customer.unit_number }}
															</p>
															<p class="mb-1" v-if="quotation.customer.address_line_1">
																{{ quotation.customer.address_line_1 }}
															</p>
															<p class="mb-1" v-if="quotation.customer.address_line_2">
																{{ quotation.customer.address_line_2 }}
															</p>
															<p class="mb-1" v-if="quotation.customer.address_country">
																{{ quotation.customer.address_country }}
																{{ quotation.customer.address_postal_code }}
															</p>
														</div>
													</div>
												</v-flex>
												<v-flex md6 lg3 v-if="quotation.customer_contact_person">
													<div>
														<p class="text-truncate mb-1 bold-600 text-capitalize">
															<span>Person Incharge</span>
														</p>
													</div>
													<p class="mb-1" v-if="quotation.customer_contact_person.display_name">
														<v-icon left small>mdi-account</v-icon>
														{{ quotation.customer_contact_person.display_name }}
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer_contact_person.phone">
														<v-icon left small>mdi-phone</v-icon>{{ quotation.customer_contact_person.phone }}
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer_contact_person.email">
														<v-icon left small>mdi-email</v-icon>{{ quotation.customer_contact_person.email }}
													</p>
													<p class="mb-1" v-if="quotation.customer_contact_person.designation">
														<v-icon left small>mdi-briefcase</v-icon>
														{{ quotation.customer_contact_person.designation }}
													</p>
												</v-flex>
												<v-flex md6 lg3 v-if="quotation.customer_default_address">
													<div>
														<p class="text-truncate mb-1 bold-600 text-capitalize">
															<span>Billing Address</span>
														</p>
													</div>
													<p class="mb-1 text-lowercase" v-if="quotation.customer_default_address.display_name">
														<v-icon left small>mdi-account</v-icon>
														{{ quotation.customer_default_address.display_name }}
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer_default_address.phone_number">
														<v-icon left small>mdi-phone</v-icon
														>{{ quotation.customer_default_address.phone_number }}
													</p>
													<p
														class="mb-1 text-lowercase"
														v-if="quotation.customer_default_address && quotation.customer_default_address.email"
													>
														<v-icon left small>mdi-email</v-icon>
														{{ quotation.customer_default_address.email }}
													</p>
													<div class="mb-1 d-flex">
														<div>
															<v-icon left small>mdi-map</v-icon>
														</div>
														<div>
															<p class="mb-1" v-if="quotation.customer_default_address.unit_number">
																{{ quotation.customer_default_address.unit_number }}
															</p>
															<p class="mb-1" v-if="quotation.customer_default_address.address_line_1">
																{{ quotation.customer_default_address.address_line_1 }}
															</p>
															<p class="mb-1" v-if="quotation.customer_default_address.address_line_2">
																{{ quotation.customer_default_address.address_line_2 }}
															</p>
															<p class="mb-1">
																<template v-if="quotation.customer_default_address.address_country">
																	{{ quotation.customer_default_address.address_country }}
																</template>
																<template v-if="quotation.customer_default_address.address_postal_code">
																	{{ quotation.customer_default_address.address_postal_code }}
																</template>
															</p>
														</div>
													</div>
												</v-flex>
												<v-flex md6 lg3 v-if="quotation.customer_address">
													<div>
														<p class="text-truncate mb-1 bold-600 text-capitalize">
															<span>Service Address</span>
														</p>
													</div>
													<p
														class="mb-1 text-lowercase"
														v-if="quotation.customer_address && quotation.customer_address.display_name"
													>
														<v-icon left small>mdi-account</v-icon>
														{{ quotation.customer_address.display_name }}
													</p>
													<p
														class="mb-1 text-lowercase"
														v-if="quotation.customer_address && quotation.customer_address.email"
													>
														<v-icon left small>mdi-email</v-icon>
														{{ quotation.customer_address.email }}
													</p>
													<p class="mb-1 text-lowercase" v-if="quotation.customer_address.phone_no">
														<v-icon left small>mdi-phone</v-icon>{{ quotation.customer_address.phone_no }}
													</p>
													<div class="mb-1 d-flex">
														<div>
															<v-icon left small>mdi-map</v-icon>
														</div>
														<div>
															<p class="mb-1" v-if="quotation.customer_address.unit_number">
																{{ quotation.customer_address.unit_number }}
															</p>
															<p class="mb-1" v-if="quotation.customer_address.address_line_1">
																{{ quotation.customer_address.address_line_1 }}
															</p>
															<p class="mb-1" v-if="quotation.customer_address.address_line_2">
																{{ quotation.customer_address.address_line_2 }}
															</p>
															<p class="mb-1">
																<template v-if="quotation.customer_address.address_country">
																	{{ quotation.customer_address.address_country }}
																</template>
																<template v-if="quotation.customer_address.address_postal_code">
																	{{ quotation.customer_address.address_postal_code }}
																</template>
															</p>
														</div>
													</div>
												</v-flex>
											</v-layout>
										</template>
									</v-expansion-panel-content>
								</v-expansion-panel>
								<v-expansion-panel v-if="quotation.project">
									<v-expansion-panel-header>
										<v-layout>
											<v-flex md10>
												<strong>Project</strong>
												-
												<span class="text-truncate mb-1 bold-500 text-capitalize">
													{{ quotation.project && quotation.project.name }}
												</span>
												<v-tooltip top>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															v-on="on"
															v-bind="attrs"
															class="text-right"
															size="16"
															:color="getStatusColor(quotation.project.status_value)"
															>mdi-circle</v-icon
														>
													</template>
													<span
														>Project Status -
														<span class="text-uppercase">{{ quotation.project.status_value }}</span></span
													>
												</v-tooltip>
											</v-flex>
										</v-layout>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="contact-person-list">
										<template>
											<v-layout>
												<v-flex md10 v-if="quotation.project">
													<div>
														<p class="text-truncate mb-1 bold-600 text-capitalize">
															<span v-if="false">
																{{ quotation.project && quotation.project.barcode }} -
																{{ quotation.project && quotation.project.name }}
															</span>
														</p>
													</div>
													<p class="mb-1" v-if="quotation.project">
														<b>Project cost : </b>
														<ShowPrice
															:object="quotation.project"
															object-key="cost"
															label="project cost"
														></ShowPrice>
													</p>
													<p class="mb-1" v-if="quotation.project && quotation.project.start_date_formatted">
														<b>Start Date : </b>
														{{ quotation.project.start_date_formatted }}
													</p>
													<p class="mb-1" v-if="quotation.project && quotation.project.end_date_formatted">
														<b>End Date : </b>
														{{ quotation.project.end_date_formatted }}
													</p>
													<p class="mb-1" v-if="quotation.project">
														<b>Description : </b>
														<ShowTextAreaValue
															:object="quotation.project"
															object-key="description"
															label="Description"
														></ShowTextAreaValue>
													</p>
												</v-flex>
											</v-layout>
										</template>
									</v-expansion-panel-content>
								</v-expansion-panel>
								<v-expansion-panel>
									<v-expansion-panel-header>
										<v-layout>
											<v-flex md10><strong>Other Details</strong></v-flex>
										</v-layout>
									</v-expansion-panel-header>
									<v-expansion-panel-content class="contact-person-list">
										<template>
											<table width="100%" class="detail-table scattered-table mt-2">
												<tr v-if="quotation.reject_remark">
													<th width="200px" valign="top" class="py-1">Reject Remark</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="reject_remark"
															label="Reject Remark"
														></ShowTextAreaValue>
													</td>
												</tr>
												<tr>
													<th width="200px" valign="top" class="py-1">Description</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="description"
															label="description"
														></ShowTextAreaValue>
													</td>
												</tr>
												<tr>
													<th width="200px" valign="top" class="py-1">Delivery Term</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="delivery_term"
															label="delivery term"
														></ShowTextAreaValue>
													</td>
												</tr>
												<tr>
													<th width="200px" valign="top" class="py-1">Valid Till</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="valid_till_formatted"
															label="valid_till_formatted"
														></ShowTextAreaValue>
													</td>
												</tr>
												<tr>
													<th width="200px" valign="top" class="py-1">Notes</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="notes"
															label="Notes"
														></ShowTextAreaValue>
													</td>
												</tr>
												<tr>
													<th width="200px" valign="top" class="py-1">Terms & Conditions</th>
													<td valign="top" class="py-1">
														<ShowTextAreaValue
															:object="quotation"
															object-key="terms_and_conditions_text"
															label="terms and conditions"
														></ShowTextAreaValue>
													</td>
												</tr>
											</table>
										</template>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>

							<div class="overflow-y" style="max-height: calc(100vh - 250px)" v-if="false">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px" v-if="false">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2" v-if="false">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/inventory-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table mt-2">
									<tr>
										<th width="300px">Remark</th>
										<td>
											<ShowTextAreaValue
												:object="quotation"
												object-key="description"
												label="Remark"
											></ShowTextAreaValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Terms & Conditions</th>
										<td>
											<ShowTextAreaValue
												:object="quotation"
												object-key="terms_and_conditions"
												label="terms and conditions"
											></ShowTextAreaValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Deadline Terms & Conditions</th>
										<td>
											<ShowTextAreaValue
												:object="quotation"
												object-key="terms_and_conditions_text"
												label="deadline terms and conditions"
											></ShowTextAreaValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="line-items">
						<QuotationLineItemListing
							v-if="quotationTab == 'line-items'"
							ref="quotation_line_items"
							:id="quotation.id"
							:item-type="'quotation'"
							class="mx-4"
							:type-uuid="uuid"
						></QuotationLineItemListing>
					</v-tab-item>
					<v-tab-item value="pending">
						<LineItemListingPending
							v-if="quotationTab == 'pending'"
							class="mx-4"
							:type-uuid="uuid"
						></LineItemListingPending>
					</v-tab-item>
					<v-tab-item value="recieved">
						<LineItemListingRecieved
							v-if="quotationTab == 'recieved'"
							class="mx-4"
							:type-uuid="uuid"
						></LineItemListingRecieved>
					</v-tab-item>
					<v-tab-item value="attachment">
						<Files
							v-if="quotationTab == 'attachment' && uuid"
							class="mx-4"
							type-text="Quotation Files"
							type="quotation"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="event">
						<EventListing
							v-if="quotationTab == 'event' && uuid"
							class="mx-4"
							type-text="Quotation"
							type="quotation"
							:type-uuid="uuid"
						></EventListing>
					</v-tab-item>
					<v-tab-item value="retire_stock_records" v-if="false">
						<ItemRetirementsListing
							v-if="quotationTab == 'retire_stock_records'"
							class="mx-4"
							type-text="Inventory"
							type="inventory"
							:uuid="uuid"
						></ItemRetirementsListing>
					</v-tab-item>
					<v-tab-item value="quantity-by-location" v-if="false">
						<QuantityLocation
							v-if="quotationTab == 'quantity-by-location'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></QuantityLocation>
					</v-tab-item>
					<v-tab-item value="transaction" v-if="false">
						<template v-if="quotationTab == 'transaction'">
							<TransactionListing
								relation-type="inventory"
								:relation-type-id="inventory.id"
							></TransactionListing>
						</template>
					</v-tab-item>
					<v-tab-item value="reservation" v-if="false">
						<ReservationListing
							v-if="quotationTab == 'reservation'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></ReservationListing>
					</v-tab-item>
					<v-tab-item value="related-service" v-if="false">
						<RelatedServiceTemplate
							v-if="quotationTab == 'related-service'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></RelatedServiceTemplate>
					</v-tab-item>
					<v-tab-item value="work-order" v-if="false">
						<WorkOrderListing
							v-if="quotationTab == 'work-order'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="comment" v-if="false">
						<Comments
							v-if="quotationTab == 'comment'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support" v-if="false">
						<SupportTemplate
							v-if="quotationTab == 'support'"
							class="mx-4"
							type-text="Perishables"
							type="inventory"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Perishables"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					!inventory.retired
						? 'To preserve associated records, you could retire the Perishables instead.'
						: null
				"
			>
				<template v-if="!inventory.retired" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							retireDialog = true;
						"
					>
						Retire
					</v-btn>
				</template>
			</DeleteTemplate>
			<!-- <Dialog :dialog="approveDialog" :dialog-width="400">
				<template v-slot:title>Are You Sure?</template>
				<template v-slot:body>
					<p>Do you really want to <b>approve</b> this Quotation?</p>
				</template>
				<template v-slot:action>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="approveDialog = false">
						Close
					</v-btn>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						depressed
						color="blue darken-4"
						tile
						v-on:click="onSubmit()"
					>
						Approve
					</v-btn>
				</template>
			</Dialog> -->
			<Dialog :dialog="rejectDialog" :dialog-width="400" v-if="false">
				<template v-slot:title>
					<span class="red--text">Are You Sure?</span>
				</template>
				<template v-slot:body>
					<p class="red--text">Do you really want to <b>reject</b> this Quotation?</p>
				</template>
				<template v-slot:action>
					<v-btn depressed tile :disabled="pageLoading" v-on:click="rejectDialog = false"> Close </v-btn>
					<v-btn
						class="white--text"
						:loading="pageLoading"
						depressed
						color="red"
						tile
						v-on:click="onSubmit()"
					>
						Reject
					</v-btn>
				</template>
			</Dialog>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
		<ApproveConfirmation
			v-if="approveDialog"
			type="Quotation"
			v-on:success="
				approveDialog = false;
				getQuotation();
			"
			v-on:close="approveDialog = false"
			:approve-dialog="approveDialog"
			status="mark_as_approved"
			:uuid="uuid"
			approve-type="quotation"
		>
		</ApproveConfirmation>
		<RejectDialog
			v-if="rejectDialog"
			type="Quotation"
			v-on:success="
				rejectDialog = false;
				getQuotation();
			"
			v-on:close="rejectDialog = false"
			:reject-dialog="rejectDialog"
			status="mark_as_rejected"
			:uuid="uuid"
			reject-type="quotation"
		>
		</RejectDialog>
		<MailTemplateDrawar
			v-if="sendForApproveDialog"
			:dialog="sendForApproveDialog"
			title="Send For Approval"
			type="quotation"
			:default-email="quotation.customer.email"
			:type-uuid="uuid"
			v-on:close="sendForApproveDialog = false"
			v-on:success="getQuotation()"
		></MailTemplateDrawar>
	</v-sheet>
</template>
<style>
.w-15 {
	width: 125px;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GetQuotation, UpdateChangePrice } from "@/core/lib/quotation.lib";
import { DownloadPDF } from "@/core/lib/purchase-order.lib";
import ImageTemplate from "@/view/components/Image";
// import Barcode from "@/view/components/Barcode";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import Dialog from "@/view/components/Dialog";
import QuantityLocation from "@/view/components/QuantityLocation";
import ReservationListing from "@/view/components/ReservationListing";
import TransactionListing from "@/view/components/TransactionListing";
import EventListing from "@/view/components/EventListing";
import WorkOrderListing from "@/view/components/WorkOrderListing";
//import { GetInventory } from "@/core/lib/inventory.lib";
import ShowValue from "@/view/components/ShowValue";
import ShowTextAreaValue from "@/view/components/ShowTextAreaValue";
// import { EventBus } from "@/core/event-bus/event.bus";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import RejectDialog from "@/view/components/RejectDialog";
import ApproveConfirmation from "@/view/components/ApproveConfirmation";
import RelatedServiceTemplate from "@/view/components/RelatedServiceTemplate";
import SupportTemplate from "@/view/components/SupportTemplate";
import ItemRetirementsListing from "@/view/components/ItemRetirementsListing";
import QuotationLineItemListing from "@/view/components/QuotationLineItemListing";
import MailTemplateDrawar from "@/view/components/MailTemplateDrawar";
import LineItemListingRecieved from "@/view/components/LineItemListingRecieved";
import LineItemListingPending from "@/view/components/LineItemListingPending";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import ShowBarcode from "@/view/components/ShowBarcode";
import ShowPrice from "@/view/components/ShowPrice";
export default {
	name: "Quotation-detail",
	title: "Detail Quotation",
	data() {
		return {
			// quotationTab: null,
			expansion_panel: [0],
			uuid: null,
			barcode: null,
			pageLoading: false,
			updatePriceLoading: false,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			sendForApproveDialog: false,
			approveDialog: false,
			rejectDialog: false,
			checkoutDialog: false,
			retireDialog: false,
			contentLoading: false,
			retireStockDialog: false,
			reservationDialog: false,
			inventory: {},
			deviceWidth: document.documentElement.clientWidth,
			product: {},
			quotation: {},
		};
	},
	created() {
		window.addEventListener("resize", this.resizeEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeEventHandler);
	},
	computed: {
		quotationTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.quotationTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "purchase-order";
			},
		},
		checkPermission: () => {
			return function () {
				if (this.quotation.status_value != "approved") {
					return true;
				}
				return false;
			};
		},
	},
	methods: {
		header_width() {
			this.$nextTick(() => {
				const header_w = document.querySelector(".header-heading").offsetWidth;
				const header_sw = document.querySelector("#header-status").offsetWidth;
				return header_w - header_sw;
			});
		},
		resizeEventHandler() {
			this.deviceWidth = document.documentElement.clientWidth;
		},
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "quotation-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "send-for-approval":
					this.sendForApproveDialog = true;
					break;
				case "approve":
					this.approveDialog = true;
					break;
				case "reject":
					this.rejectDialog = true;
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "stock-history":
					break;
			}
		},
		getQuotation() {
			this.pageLoading = true;
			this.contentLoading = true;
			GetQuotation(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.uuid = data.uuid;
					this.quotation = data;
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoading = false;
				});
		},
		downloadPDF() {
			DownloadPDF({ uuid: this.uuid, url: "generate-pdf/quotation/" });
		},
		async updateChangePrice() {
			this.updatePriceLoading = true;
			try {
				await UpdateChangePrice(this.uuid);
				this.getQuotation();
				if (this.quotationTab == "line-items") {
					this.$refs.quotation_line_items.getQuotationLineItems(this.quotation.id);
				}
			} catch (error) {
				console.log(error);
			} finally {
				this.updatePriceLoading = false;
			}
		},
	},
	components: {
		Files,
		Dialog,
		Comments,
		QuantityLocation,
		ReservationListing,
		TransactionListing,
		EventListing,
		WorkOrderListing,
		ImageTemplate,
		ShowValue,
		ShowTextAreaValue,
		ItemRetirementsListing,
		DeleteTemplate,
		ApproveConfirmation,
		RejectDialog,
		RelatedServiceTemplate,
		SupportTemplate,
		QuotationLineItemListing,
		MailTemplateDrawar,
		LineItemListingRecieved,
		LineItemListingPending,
		TooltipQuestion,
		ShowBarcode,
		ShowPrice,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Quotation", disabled: true, href: "/quotation" },
			{ text: "Detail", disabled: true, href: "#" },
		]);
		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}

		this.getQuotation();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
